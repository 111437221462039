<template>
  <div>
    <BaseDialog class="dialog-warpper" :dialogVisible="visible" :title="title" width="60%" @closeHandle="handleClose">
      <template>
        <BatchOperation v-if="type == 'batchPutOff'">
          <el-checkbox v-model="checkedAll" :indeterminate="indeterminate" @change="changeCheckAll">全选</el-checkbox>
          <el-pagination
            class="defaultClass"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.pageIndex"
            :hide-on-single-page="true"
            :page-sizes="[10, 20, 50, 100, 200]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="selectionDataCopy.length"
          >
          </el-pagination>
        </BatchOperation>
        <el-table
          ref="tableRef"
          border
          height="auto"
          max-height="400px"
          style="width: 100%"
          :cell-class-name="cellClassName"
          :data="finalData"
          @select="selectHandler"
          @select-all="selectAllHandler"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="name" label="公共产品名称"> </el-table-column>
          <el-table-column label="SKU" width="400">
            <template slot-scope="{ row }">
              <div :key="index" v-for="(item, index) in getSku(row)" class="row-line">
                <el-checkbox v-model="item.checked">{{ item.sku }}</el-checkbox>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="款式">
            <template slot-scope="{ row }">
              <div v-for="(item, index) in getStyleName(row)" :key="index" class="row-line">
                <el-tag type="primary">
                  {{ item.styleName }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="尺码">
            <template slot-scope="{ row }">
              <div :key="index" v-for="(item, index) in getSize(row) || []" class="row-line">
                <el-tag type="primary">
                  {{ item }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="type == 'batchPutOff'"
          class="defaultClass"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.pageIndex"
          :hide-on-single-page="true"
          :page-sizes="[10, 20, 50, 100, 200]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="selectionDataCopy.length"
        >
        </el-pagination>
      </template>
      <template #footer>
        <div :class="{ ac: true, 'flex-between': type == 'putOn' }">
          <div class="info" v-if="type == 'putOn'">公共产品上架后，业务可用公共产品的SKU直接下单</div>
          <div class="tr">
            <loadingBtn type="primary" @click="onConfirm"> 确定 </loadingBtn>
            <loadingBtn @click="onCancel"> 取消 </loadingBtn>
          </div>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { deepClone } from '@/components/avue/utils/util'
import checkAllData from '@/views/components/checkAllData3'

export default {
  components: { checkAllData },
  watch: {
    checkedAll() {
      this.clearArr = [] // 当全选为false状态时，里面还有东西，但我们用不上
      this.multipleSelection = [] // 当全选为true状态时，里面还有东西，但我们用不上
    },
    multipleSelection: {
      handler(newVal) {
        if (this.multipleSelection.length == this.total) {
          this.checkedAll = true
        }
      },
      deep: true
    },
    clearArr: {
      handler(newVal) {
        console.log('clearArr', newVal)
      },
      deep: true
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    selectionData: {
      type: Array,
      default: () => []
    },
    sup_this: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'putOn'
    }
  },

  data() {
    return {
      selectionDataCopy: [],
      page: {
        pageIndex: 1,
        pageSize: 10
      },
      checkList: {},
      checkedAll: false,
      tableRef: {},
      multipleSelection: [],
      clearArr: []
    }
  },

  watch: {
    finalData: {
      handler(newVal) {
        newVal.map((item) => {
          const { customProductList } = item
          customProductList.map((cItem) => {
            const { customSpecificProductList } = cItem
            const checked = customSpecificProductList.every((pItem) => pItem.checked)
            cItem.checked = checked
          })
          const itemChecked = customProductList.every(({ checked }) => checked)
          item.checked = itemChecked
          const tableRef = this.$refs.tableRef
          if (tableRef) {
            tableRef.toggleRowSelection(item, item.checked)
          }
        })
      },
      deep: true
    }
  },

  computed: {
    indeterminate() {
      if (this.checkedAll) {
        return !!this.clearArr.length
      }
      return !!this.multipleSelection.length && this.multipleSelection.length != this.total
    },
    title() {
      let title = ''
      switch (this.type) {
        case 'putOn':
          title = '公共产品上架'
          break
        case 'putOff':
          title = '公共产品下架'
          break
        case 'batchPutOff':
          title = '公共产品批量下架'
          break
      }
      return title
    },
    getSize() {
      return ({ customProductList }) => {
        return customProductList
          ?.map(({ customSpecificProductList }) =>
            customSpecificProductList
              ?.map(({ sizeName }) => sizeName)
              .flat()
              .filter(Boolean)
          )
          .flat()
      }
    },
    finalData() {
      this.$nextTick(() => {
        const { multipleSelection, clearArr, checkedAll } = this
        const data = checkedAll ? clearArr : multipleSelection
        if (checkedAll) {
          this.selectionDataCopy
            .filter(({ id }) => !data.includes(id))
            .forEach((row) => {
              if (row) {
                this.tableRef.toggleRowSelection(row, true)
              }
            })
        } else {
          this.selectionDataCopy
            .filter(({ id }) => data.includes(id))
            .forEach((row) => {
              if (row) {
                this.tableRef.toggleRowSelection(row, true)
              }
            })
        }
      })
      const start = (this.page.pageIndex - 1) * this.page.pageSize
      return this.selectionDataCopy.slice(start, start + this.page.pageSize)
    },
    getSku() {
      return ({ customProductList }) => {
        return customProductList?.map(({ customSpecificProductList }) => customSpecificProductList).flat()
      }
    }
  },

  created() {
    this.selectionDataCopy = deepClone(
      this.selectionData.map((item) => {
        const { customProductList = [] } = item
        customProductList.map((cItem) => {
          const { customSpecificProductList } = cItem
          cItem.checked = false
          customSpecificProductList.map((pItem) => {
            pItem.checked = false
          })
        })
        item.checked = false
        return item
      })
    )
  },
  mounted() {
    this.$nextTick(() => {
      this.tableRef = this.$refs.tableRef
    })
  },
  methods: {
    selectAllHandler(selection) {
      this.toggleChecked(this.finalData, !!selection.length)
      return
      this.selectionDataCopy.forEach((row) => {
        this.$set(row, checked, !!selection.length)
        // row.checked = !!selection.length
      })
      row.customProductList.checked = row.checked
      row.customProductList.map(({ customSpecificProductList }) => (customSpecificProductList.checked = row.checked))
      const { clearArr, multipleSelection } = this
      if (!this.checkedAll) {
        const isDel = !selection.length
        if (isDel) {
          const ids = this.selectionDataCopy.map(({ id }) => id)
          ids.map((id) => {
            const fIndex = multipleSelection.findIndex((cId) => cId == id)
            if (fIndex > -1) {
              multipleSelection.splice(fIndex, 1)
            }
          })
        } else {
          const ids = this.selectionDataCopy.map(({ id }) => id)
          this.multipleSelection = [...new Set(this.multipleSelection.concat(ids))]
        }
        return
      }
      const isClear = !!selection.length
      if (isClear) {
        const ids = selection.map(({ id }) => id)

        ids.map((id) => {
          const fIndex = clearArr.findIndex((cId) => cId == id)
          if (fIndex > -1) {
            clearArr.splice(fIndex, 1)
          }
        })
      } else {
        const ids = this.selectionDataCopy.map(({ id }) => id)
        this.clearArr = [...new Set(this.clearArr.concat(ids))]
      }
    },

    toggleChecked(data, bool = false) {
      if (!Array.isArray(data)) {
        data = [data]
      }

      data.map((item) => {
        const { customProductList } = item
        customProductList.map((cItem) => {
          const { customSpecificProductList } = cItem
          customSpecificProductList.map((pItem) => {
            pItem.checked = bool
          })
          const checked = customSpecificProductList.every((pItem) => pItem.checked)
          cItem.checked = checked
        })
        const itemChecked = customProductList.every(({ checked }) => checked)
        item.checked = itemChecked
      })
    },

    selectHandler(selection, row) {
      console.log('selection, row', selection, row)
      this.toggleChecked([row], !row.checked)
      return
      // const selectionIdList = selection.map(({ id }) => id)
      // const skuList = row.customProductList
      //   .map(({ customSpecificProductList }) => customSpecificProductList.map(({ sku }) => sku))
      //   .flat()
      // skuList.map((item) => (this.checkList[item] = selectionIdList.includes(row.id)))
      if (!this.checkedAll) {
        const isExist = this.multipleSelection.includes(row.id)
        if (isExist) {
          const delIndex = this.multipleSelection.findIndex((id) => id == row.id)
          this.multipleSelection.splice(delIndex, 1)
        } else {
          this.multipleSelection.push(row.id)
        }
        return
      }
      const isExist = this.clearArr.includes(row.id)
      if (isExist) {
        const delIndex = this.clearArr.findIndex((id) => id == row.id)
        this.clearArr.splice(delIndex, 1)
      } else {
        this.clearArr.push(row.id)
      }
    },
    changeCheckAll(val) {
      if (val) {
        this.selectionDataCopy.forEach((row) => {
          if (row) {
            this.tableRef.toggleRowSelection(row, true)
          }
        })
      } else {
        this.tableRef.clearSelection()
      }
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
    handleSizeChange(val) {
      this.page.pageSize = val
    },
    handleCurrentChange(val) {
      this.page.pageIndex = val
    },
    cellClassName({ columnIndex }) {
      if ([2, 3, 4].includes(columnIndex)) {
        return 'clearTablePadding'
      }
    },
    getStyleName(row) {
      let tempArr = row.customProductList
      const amount = this.getSize(row).length
      let a = []
      for (let i = 0; i < tempArr.length; i++) {
        for (let j = 0; j < Math.ceil(amount / tempArr.length); j++) {
          a.push(tempArr[i])
        }
      }
      return a
    },
    onCancel() {
      this.$emit('update:selectionData', this.selectionDataCopy)
      this.$emit('update:visible', false)
    },
    async onConfirm() {
      try {
        const data = {}
        this.$emit('update:visible', false)
        this.sup_this.init()
        this.$message.success('上架成功')
      } catch (error) {}
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  .defaultClass.el-pagination {
    text-align: right;
    padding: 10px 0;
    padding-right: 15px;
  }
  .row-line {
    border-bottom: 1px solid #ebeef5;
    margin: 0 -10px;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .row-line:last-child {
    border-bottom: none;
  }
  .tr {
    text-align: right;
  }
  .ac {
    align-items: center;
  }
  .info {
    color: #bdb8b8;
  }
}
</style>
